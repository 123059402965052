var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column overflow-hidden h-100"},[_c('vue-custom-scrollbar',{staticClass:"app-content-padding flex-grow-1 overflow-hidden h-100"},[_c('ts-page-title',{attrs:{"title":_vm.$t('branch.pageTitle'),"breadcrumb":[
                { text: _vm.$t('home'), href: '/' },
                {
                    text: _vm.$t('branch.pageTitle'),
                    href: '/admin/branches'
                },
                {
                    text: _vm.$t('create'),
                    active: true
                }
            ]}}),_c('div',{staticClass:"demo-spin-article"},[_c('Profile',{ref:"profile",staticClass:"tw-h-full",attrs:{"validate":_vm.errors},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}})],1),(_vm.loading)?_c('Spin',{attrs:{"size":"large","fix":""}}):_vm._e()],1),_c('div',{staticClass:"app-footer m-0 tw-flex tw-justify-end tw-space-x-2 tw-sh",staticStyle:{"background-color":"#e9ecef"},attrs:{"id":"footer"}},[_c('ts-button',{staticClass:"btn-gray",on:{"click":function($event){$event.preventDefault();return _vm.$router.push({ name: 'branch' })}}},[_vm._v(_vm._s(_vm.$t('cancel')))]),_c('ts-button',{attrs:{"color":"primary","outline":"","waiting":_vm.waiting_new,"disabled":_vm.waiting || _vm.waiting_new},on:{"click":function($event){$event.preventDefault();return _vm.onSaveAddNew.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t('saveAddNew')))]),_c('ts-button',{attrs:{"color":"primary","waiting":_vm.waiting,"disabled":_vm.waiting_new || _vm.waiting},on:{"click":function($event){$event.preventDefault();return _vm.onSave.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t('save')))])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }